import tableFiltersInput from "./TableFiltersInput.vue";
import tableFiltersSelect from "./TableFiltersSelect.vue";
import dateRangeTableHeader from "./DateRangeTableHeader.vue";
import tableFiltersDateRange from "./TableFiltersDateRange.vue";
import sharedPagination from "./SharedPagination.vue";
import textFilter from "./TextFilter.vue";
export const TableFiltersInput: any = tableFiltersInput;
export const TableFiltersSelect: any = tableFiltersSelect;
export const DateRangeTableHeader: any = dateRangeTableHeader;
export const TableFiltersDateRange: any = tableFiltersDateRange;
export const SharedPagination: any = sharedPagination;
export const TextFilter: any = textFilter;
