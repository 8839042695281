













import { ref, defineComponent, reactive, toRef } from "@vue/composition-api";
import { SelectFilter } from "@/InterfaceModel/Filter";
import DateRangeTableHeader from "./DateRangeTableHeader.vue";
export default defineComponent({
 props: {
  ownHeader: {
   type: Object,
  },
  listenChange: {
   type: Function,
  },
 },
 components: { DateRangeTableHeader },
 setup: props => {
  let ownHeader: Record<string, any> | undefined = toRef(props, "ownHeader");
  let listenChange: Record<string, any> = toRef(props, "listenChange");
  const { hasKey,key, placeholder, items } = ownHeader.value.filters;
  const queryString = ref<Record<string, any>>({});
  let refQuery = ref<number>(0);
  let filterDateKey = ref<number>(0);
  const setRefQuery = (val: number) => {
   refQuery.value = val;
  };
  const handleEmitFilter = () => {
   clearTimeout(refQuery.value);
   let _obj : Record<string,string>={}
   const _value = queryString.value
   Object.keys(_value).forEach(o=>{
     _obj[`${hasKey?key+'.':''}${o}`]= _value[o]
   })
   // let payload: Record<string,string> = {
   //  key,
   //  value: queryString.value,
   // };
   listenChange.value(_obj);
  };
  const pickedDate = (_val: any) => {
   queryString.value = _val.value
  };

  return { filterDateKey, items, placeholder, queryString, refQuery, setRefQuery, handleEmitFilter, pickedDate };
 },

 watch: {
  queryString() {
   clearTimeout(this.refQuery);
   this.setRefQuery(
    setTimeout(() => {
     this.handleEmitFilter();
    }, 1000),
   );
  },
 },
});
